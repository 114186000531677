import { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion';

import cx from 'lib/cx';
import style from './FormInput.module.css';

const FormCheckbox = forwardRef(
  ({ name, value, readOnly, disabled, status, onChange, themed, className }, ref) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const canInteract = !['disabled', 'readonly'].includes(state);

    return (
      <label className={cx(style[status], { 'cursor-pointer': canInteract }, className)}>
        <input
          name={name}
          id={name}
          type="checkbox"
          className="hidden"
          value={value}
          onChange={() => onChange(!value)}
          ref={ref}
          disabled={disabled}
          readOnly={readOnly}
        />
        <span
          className={cx(
            'flex justify-center items-center h-5 w-5 rounded-md transition-all duration-200 shrink-0 border-2',
            {
              'bg-white': !value,
              [style.statusBorderColor]: !value,
              'border-green-500 bg-green-500': value && !themed,
              'border-theme-primary bg-theme-primary': value && themed,
              'border-gray-400 bg-gray-400': disabled,
            }
          )}
        >
          {value && (
            <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} className="block">
              <FontAwesomeIcon icon={faCheck} size="xs" className="text-white" />
            </motion.span>
          )}
        </span>
      </label>
    );
  }
);

FormCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOf([true, false, {}]),
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  themed: PropTypes.bool,
  className: PropTypes.string,
};

FormCheckbox.defaultProps = {
  name: null,
  value: false,
  status: 'default',
  readOnly: false,
  disabled: false,
  themed: false,
  className: '',
};

export default FormCheckbox;
