import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { faSearch, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useOutsideClickRef, useKey } from 'rooks';

import config from 'config';
import useScrollLock from 'hooks/useScrollLock';
import useScreen from 'hooks/useScreen';
import FormSearchInput from 'components/form/FormSearchInput';
import ModalPage from './ModalPage';
import AppBar from './AppBar';
import NavbarIconButton from './NavbarIconButton';

const AppBarSearch = ({
  isFocused,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  buttonTheme,
  buttonClassName,
  largeScreenDefaultDisplay,
  children,
}) => {
  const screen = useScreen();
  const [container] = useOutsideClickRef(onBlur);

  useScrollLock(isFocused && value);

  useKey(['Escape'], () => {
    document.activeElement.blur();
    onBlur();
  });

  if (!screen.lg) {
    return (
      <>
        <NavbarIconButton
          as="button"
          type="button"
          onClick={onFocus}
          icon={faSearch}
          theme={buttonTheme}
          className={buttonClassName}
        />
        <ModalPage show={isFocused} onHide={onBlur}>
          <div className="relative">
            <AppBar className="shrink-0 z-40 flex justify-between items-center" theme="light">
              <motion.div
                initial={{ opacity: 0, y: -25 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="px-3 flex items-center w-full"
              >
                <div className="shrink-0">
                  <NavbarIconButton as="button" type="button" onClick={onBlur} icon={faArrowLeft} />
                </div>
                <div className="grow">
                  <FormSearchInput
                    type="text"
                    name="query"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    autoComplete="off"
                    autoFocus
                  />
                </div>
              </motion.div>
            </AppBar>
            {children}
          </div>
        </ModalPage>
      </>
    );
  }

  return (
    <div className="relative ml-8" ref={container}>
      {largeScreenDefaultDisplay === 'button' && !isFocused && (
        <NavbarIconButton
          as="button"
          type="button"
          onClick={onFocus}
          icon={faSearch}
          theme={buttonTheme}
          className={buttonClassName}
        />
      )}

      {(isFocused || largeScreenDefaultDisplay === 'input') && (
        <motion.div
          className="hidden lg:block p-px max-w-full"
          initial={largeScreenDefaultDisplay === 'input' ? false : { opacity: 0 }}
          animate={{
            opacity: 1,
            width: config(`/tw/spacing/${isFocused ? '80' : '40'}`),
            transition: { delay: isFocused ? 0 : 0.3 },
          }}
        >
          <FormSearchInput
            type="text"
            name="query"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            autoComplete="off"
            onFocus={onFocus}
            autoFocus={isFocused}
          />
        </motion.div>
      )}
      {isFocused && value && (
        <motion.div
          key="search-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.8 }}
          className="bg-gray-900 fixed inset-0 mt-20"
          onClick={onBlur}
        />
      )}
      {children && (
        <motion.div
          key="search-results"
          initial={{ opacity: 0, y: -25 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
          className="absolute top-full inset-x-0 bg-white shadow-2xl rounded-lg mt-2 overflow-hidden"
        >
          <div className="overflow-auto" style={{ maxHeight: '80vh' }}>
            {children}
          </div>
        </motion.div>
      )}
    </div>
  );
};

AppBarSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  buttonTheme: PropTypes.oneOf(['light', 'dark']),
  buttonClassName: PropTypes.string,
  children: PropTypes.node,
  largeScreenDefaultDisplay: PropTypes.oneOf(['button', 'input']),
};

AppBarSearch.defaultProps = {
  placeholder: 'Search...',
  buttonTheme: 'light',
  buttonClassName: '',
  children: null,
  largeScreenDefaultDisplay: 'input',
};

export default AppBarSearch;
