import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import Highlight from 'react-highlight-words';

import cx from 'lib/cx';
import { formatInitials } from 'lib/formatters';
import Avatar from 'components/common/Avatar';
import FormCheckboxNoLabel from 'components/form/FormCheckboxNoLabel';

const InviteGroupItem = ({
  avatar,
  name,
  detail,
  highlight,
  isSelected,
  isDisabled,
  hideDeselectedBox,
  onHideDropdown,
  onSelect,
}) => (
  <button
    type="button"
    onClick={!isDisabled ? onSelect : () => onHideDropdown()}
    className={cx(
      'w-full flex items-center px-6 py-4 text-left gap-4 transition-colors duration-200',
      { 'hover:bg-gray-200': !isDisabled }
    )}
  >
    <div className="shrink-0">
      {avatar ? (
        <Avatar
          size="sm"
          image={avatar}
          className={cx('transition-opacity duration-200', { 'opacity-50': isDisabled })}
          themed
        />
      ) : (
        <div
          className={cx(
            'w-10 h-10 rounded-full bg-theme-primary flex items-center justify-center text-white transition-colors duration-200',
            { 'bg-gray-400': isDisabled }
          )}
        >
          {name ? formatInitials(name) : <FontAwesomeIcon icon={faEnvelope} size="lg" />}
        </div>
      )}
    </div>
    <div className="flex-1 space-y-1 overflow-hidden">
      {name && (
        <p
          className={cx('leading-none transition-colors duration-200 truncate', {
            'text-gray-500': isDisabled,
          })}
        >
          <Highlight
            textToHighlight={name}
            searchWords={[highlight]}
            highlightClassName="font-medium"
            highlightTag="span"
            autoEscape
          />
        </p>
      )}
      <p
        className={cx('leading-none transition-colors duration-200 truncate', {
          'text-gray-600': !!name,
          'text-gray-500': isDisabled,
        })}
      >
        <Highlight
          textToHighlight={detail}
          searchWords={[highlight]}
          highlightClassName="font-medium"
          highlightTag="span"
          autoEscape
        />
      </p>
    </div>
    <div className={cx('shrink-0 ', { invisible: !isSelected && hideDeselectedBox })}>
      <FormCheckboxNoLabel value={isSelected} onChange={onSelect} disabled={isDisabled} themed />
    </div>
  </button>
);

InviteGroupItem.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  detail: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hideDeselectedBox: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onHideDropdown: PropTypes.func.isRequired,
  highlight: PropTypes.string,
};

InviteGroupItem.defaultProps = {
  avatar: null,
  name: null,
  isSelected: false,
  isDisabled: false,
  hideDeselectedBox: false,
  highlight: '',
};

export default InviteGroupItem;
